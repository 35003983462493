import React from 'react';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import Pagination from '@/components/pagination';
import Icon from 'supercons';
import { graphql, Link } from 'gatsby';
import { formatDate } from '@/model/format-date';

interface QueryProps {
  allFile: {
    nodes: {
      childMarkdownRemark: Node;
    }[];
  };
}

interface Node {
  frontmatter: {
    title: string;
    start: string;
    end?: string;
    price?: number;
    teacher?: string;
  };
  fields: {
    slug: string;
  };
  excerpt: string;
}

export default function events({ data, pageContext }: { data: QueryProps, pageContext: any }) {
  const events = data.allFile.nodes.map(({ childMarkdownRemark: node }) => event(node));

  return (
    <Layout>
      <SEO title="Events + Classes" />

      <div className="mx-auto" style={{ maxWidth: '900px' }}>
        <h1
          className="pt-8 text-4xl font-bold md:text-5xl text-center"
          title="Events + Classes">
          Events + Classes
        </h1>

        <section className="w-full px-4 py-12 mx-auto max-w-7xl md:w-3/4">
          {events}
        </section>

        <Pagination {...pageContext} />
      </div>
    </Layout>
  )
}

function event(node: Node) {
  return (
    <Link
      className="post-list-item p-8 block hover:border-wheat border-transparent border-2 transition-all rounded-sm"
      to={`/events${node.fields.slug}`}
      key={node.fields.slug}>
      <p className="mb-4 text-base text-clay font-title">
        {formatDate(node.frontmatter.start)}
      </p>

      <h2 className="mb-2 text-xl font-extrabold leading-snug tracking-tight text-gray-800 md:text-3xl">
        {node.frontmatter.title}
      </h2>

      {
        node.frontmatter.teacher && (
          <p className="mb-4 text-xs uppercase font-normal tracking-wide font-title opacity-90">
            Taught by {node.frontmatter.teacher}
          </p>
        )
      }

      <p className="mb-4 text-base">
        {node.excerpt}
      </p>
    </Link>
  );
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allFile(
      filter: {sourceInstanceName: {eq: "events"}}
      sort: {fields: childrenMarkdownRemark___frontmatter___start, order: DESC}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            start
            end
            price
            teacher
            title
          }
          fields {
            slug
          }
          excerpt(pruneLength: 200, truncate: true)
        }
      }
    }
  }
`;
